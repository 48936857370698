.component {
    position: absolute;
    top: 80px;
    width: 100vw;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background: linear-gradient(to right, #8e9eab, #eef2f3);
}

.documents_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 0;
    width: 100vw;
    height: 100vh;
    margin-left: 20px;
    list-style-type: none;
}

.plus_icon {
    position: fixed ;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    font-size: 30px;
    background-color: white;
    border-radius: 40px;
    box-shadow: 0px 2px 10px #00000080;
}

