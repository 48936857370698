.component {
    position: absolute;
    z-index: 999;
    top: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background-color: #EEEEEE;
}

.close_button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: red;
    border-radius: 40px;
    box-shadow: 0px 2px 10px #00000080;
}

.editor {
    width: 100vw; 
    height: 150px;
    background-color: white;
    position: absolute;
    top:-80px;
}

.hid {
    position: absolute;
    bottom: -80px;
    left: 0px;
    width: 100vw; 
    height: 150px;
    background-color: red;
    overflow: hidden;
}

.text_input {
    position: absolute;
    bottom: 10px;
    left: 10px;
    border-radius: 40px;
    box-shadow: 0px 2px 10px #00000080;
}

