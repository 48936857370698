.component {
    position: absolute;
    top: 80px;
    width: 100vw;
    height: calc(100vh - 80px);
    /* display: flex; */
    flex-direction:  column;
    flex-wrap: wrap;
    background: linear-gradient(to right, #abbaab, #ffffff)
}

.add_note_button {
    position: absolute;
    bottom: 20px;
    right: 20px; 
    width: 60px;
    height:60px;
    background-color: white;
    border-radius: 40px;
    box-shadow: 0px 2px 10px #00000080;
}

.mainDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.fileContainer {
    border: 1px solid #ccc; /* Light gray border */
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px; /* Optional: rounded corners */
    display: flex;
    flex-direction: column;
}
.gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 10px;
}

.gridItem {
    background-color: #a8b6a7;;
    text-align: center;
    border: 1px solid #ccc;
    overflow: scroll;
    height: 570px;
}

.fileContainer {
    margin-bottom: 10px;
}

.add_note_button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;
}

.element{
    width: 1060px;
    height: 40px;
    margin-top: 20px;
    border-radius: 24px;
    backdrop-filter: blur(31px);
    padding: 40px;
    font: normal normal 600 48px/54px "bio-sans";
}


.title {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
    font-family: 'Cute Font', cursive;
}

.topicCard {
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.topicCard:hover {
    transform: translateY(-3px);
}

.headerTopic {
    font-size: 1.2em;
    color: #555;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-family: 'Cute Font', cursive;
}

.topicIcon {
    margin-right: 8px;
}

.subtopic {
    margin-left: 20px;
    font-size: 1em;
    color: #777;
    display: flex;
    align-items: center;
}

.subtopicIcon {
    margin-right: 5px;
}

.addSubButton, .deleteButton {
    
    font-size: 12px;
    padding: 10px 15px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    display: inline-flex;
    align-items: center;
}

.addSubButton {
    background-color: #a29dc9;
    color: #fff;
}

.addSubButton:hover {
    background-color: #a29bfe;
    transform: translateY(-2px);
}

.addSubButton:active {
    background-color: #5a4ae3;
}

.deleteButton {
    background-color: #ff7675;
    color: #fff;
}

.deleteButton:hover {
    background-color: #ff9a8d;
    transform: translateY(-2px);
}

.deleteButton:active {
    background-color: #e65c5b;
}

.addSubButton::before {
    content: '➕';
    align-self: center;
}

.deleteButton::before {
    content: '❌';
}