.modal
.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 999;
}

.overlay {
    background: rgba(49, 49, 49, 0.8);
    z-index: 999;
}

.modalContent {
    z-index: 999;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px;
    /* line-height: 1.4;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px; */
    /* width: '500px';
    height: '500px'; */
    background-color: #FFFFFFCC;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.btn {
    width: 50px;
}